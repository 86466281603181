import LoadingScreen from "components/LoadingScreen";
import { createContext, ReactNode, useReducer, useMemo } from "react";

import { initialState, rootReducer } from "./reducer";

// props type
type EmailAnalyticsProviderProps = { children: ReactNode };
// --------------------------------------------------------
const EmailAnalytics = createContext({
  ...initialState,
});

export const EmailAnalyticsProvider = ({
  children,
}: EmailAnalyticsProviderProps) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const store = useMemo(() => [state, dispatch], [state]) as any;

  // show loading until not initialized
  if (!state.isInitialized) <LoadingScreen />;

  return (
    <EmailAnalytics.Provider value={store}>{children}</EmailAnalytics.Provider>
  );
};

export default EmailAnalytics;
