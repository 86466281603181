import { Box, Grid, styled } from "@mui/material";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import Announcement from "./Announcement";
import useStyles from "./styles";

type DashboardHeaderProps = {};

const FooterRoot = styled(Box)(({ theme }) => ({
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "#7244ff",
  color: theme.palette.text.primary,
}));

const Footer: FC<DashboardHeaderProps> = () => {
  const css = useStyles();

  return (
    <Box>
      <Announcement />
      <FooterRoot>
        <Box width="90%" margin="auto">
          <Grid container>
            <Grid item md={6} xs={12}>
              <Box
                display={"flex"}
                flexDirection="column"
                justifyContent="center"
                height="100%"
              >
                <Box>
                  <NavLink to="/privacy-policy" className={css.link}>
                    Privacy policy
                  </NavLink>
                </Box>
                <Box>
                  <NavLink to="/terms" className={css.link}>
                    Terms & Conditions
                  </NavLink>
                </Box>
                <Box>
                  <NavLink to="/faqs" className={css.link}>
                    FAQs
                  </NavLink>
                </Box>
                <Box className={css.copyright} mt={1}>
                  © {new Date().getFullYear()} FlashMonk Pvt Ltd. Crafted with love
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              display={"flex"}
              flexDirection="column"
              alignItems="flex-end"
            >
              <img
                src="/static/illustration/project-management-dashboard.svg"
                alt="Illustration"
                className={css.illustration}
              />
            </Grid>
          </Grid>
        </Box>
      </FooterRoot>
    </Box>
  );
};

export default Footer;
