import { Box } from "@mui/system";
import "./style.scss";

export const PrivacyPolicy = () => {
  return (
    <Box width="100%" justifyContent="center" display="flex" className="privacy-content">
        <div className="c20 c29 doc-content">
            <p className="c20 c24"><span className="c1">Last Updated: June 07, 2024</span></p>
            <p className="c24 c20 c33"><span className="c1"></span></p>
            <p className="c20 c23"><span className="c8 c4">PRIVACY POLICY</span></p>
            <p className="c21"><span className="c1">&nbsp;</span></p>
            <p className="c21">
                <span className="c4">Flashmonk Private Limited</span><span className="c5">,</span><span className="c4">&nbsp;</span><span className="c5">CIN No. U72900KA2022PTC157982 (&ldquo;</span><span className="c4">Company</span>
                <span className="c5">&rdquo;,&nbsp;&quot;</span><span className="c4">We</span><span className="c5">&quot;</span><span className="c4">, </span><span className="c5">&quot;</span><span className="c4">Us</span>
                <span className="c5">&quot;</span> <span className="c4">, </span><span className="c5">or</span><span className="c4">&nbsp;</span><span className="c5">&quot;</span><span className="c4">Our</span>
                <span className="c5">
                    &quot;), a private limited company with its registered office at 649, KH No 555/649, 13th Cross, 27th Main Road, 1st Sector, HSR Layout Bengaluru Bangalore - 560102, Karnataka, India which owns and operates the website
                    https://flash.tech and the website application &lsquo;the Email Ninja&rsquo; at https://www.emailninja.tech (
                </span>
                <span className="c5 c15">collectively referred to as</span><span className="c5">&nbsp;the &ldquo;</span><span className="c4">Platform</span>
                <span className="c5">
                    &rdquo;) is committed to protecting Your privacy and the information that You share while using the Platform. We value the trust You place in Us. Therefore, We maintain the highest security standards for securing the
                    transactions and Your Information
                </span>
                <span className="c5 c15">(defined below)</span><span className="c1">. &nbsp; &nbsp;</span>
            </p>
            <p className="c7"><span className="c1"></span></p>
            <p className="c21 c20">
                <span className="c5">This privacy policy (&ldquo;</span><span className="c4">Privacy Policy</span>
                <span className="c5">
                    &rdquo;) specifies the manner in which Your personal data and other information is collected, received, stored, processed, disclosed, transferred, dealt with or otherwise handled by the Company in relation to the services
                    offered on the Platform (&ldquo;
                </span>
                <span className="c4">Services</span>
                <span className="c1">
                    &rdquo;). This Privacy Policy does not apply to information that You provide to, or that is collected by, any third-party through the Platform, and any third-party sites that You access or use in connection with the services
                    offered on the Platform.
                </span>
            </p>
            <p className="c7"><span className="c1"></span></p>
            <p className="c20 c21"><span className="c1">Please read the Privacy Policy carefully prior to using or registering on the Platform or accessing any material, information or availing any Services through the Platform. </span></p>
            <p className="c7"><span className="c1"></span></p>
            <p className="c21">
                <span className="c5">By clicking the &ldquo;</span><span className="c5 c15">Connect Gmail&rdquo; </span><span className="c5">button provided at the welcome page for the Platform, users (</span>
                <span className="c5 c15">&ldquo;</span> <span className="c4">You</span><span className="c4 c15">&rdquo;, &ldquo;</span><span className="c4">Your</span><span className="c4 c15">&rdquo;&nbsp;</span>
                <span className="c5">as applicable)&nbsp;accept and agree to be bound by the terms and conditions of this Privacy Policy. This Privacy Policy is incorporated into Our&nbsp;terms of use </span>
                <span className="c4">(&ldquo;Terms&rdquo;)&nbsp;</span>
                <span className="c1">and shall be read harmoniously and in conjunction with the Terms. All capitalized terms used and not defined in this Privacy Policy shall have the meaning ascribed to them under the Terms.</span>
            </p>
            <p className="c7">
                <span className="c8 c4"><br /></span>
            </p>
            <p className="c21 c20">
                <span className="c1">
                    This Privacy Policy (i) is an electronic record under the Information Technology Act, 2000 read with rules and regulations made thereunder and is generated by a computer system; and (ii) will not require any physical,
                    electronic, or digital signature.
                </span>
            </p>
            <p className="c7"><span className="c1"></span></p>
            <ol className="c14 lst-kix_y78o8pdvzzyp-0 start" start={1}>
                <li className="c6 li-bullet-0"><span className="c4">Collection of Information:</span></li>
            </ol>
            <p className="c7 c16"><span className="c1"></span></p>
            <p className="c11">
                <span className="c5">We collect Personal Information (</span><span className="c5 c15">defined below</span>
                <span className="c1">
                    ) from You when You register or set up an account with Us or login on the Platform. You may browse certain sections of the Platform without being registered with Us. However, to avail the Services on the Platform You are
                    required to set up an Account with Us.
                </span>
            </p>
            <p className="c18 c28"><span className="c1"></span></p>
            <p className="c11 c20"><span className="c1">This Privacy Policy applies to the following information:</span></p>
            <p className="c18"><span className="c1"></span></p>
            <ol className="c14 lst-kix_u0cqxtlwqbko-2 start" start={1}>
                <li className="c21 c27 li-bullet-0">
                    <span className="c5 c22">Information You give Us</span>
                    <span className="c1">
                        : When You start using the Platform, You may provide certain information to Us voluntarily while registering on Our Platform for availing Our Services, such as email address, etc. We will collect this information through
                        various means and in various places through the Platform, including account registration forms, contact us forms, or when You otherwise interact with the Platform including at customer support.
                    </span>
                </li>
            </ol>
            <p className="c10"><span className="c5 c17"></span></p>
            <p className="c13">
                <span className="c1">
                    We may ask You to provide certain additional information about Yourself on a case-to-case basis. All information disclosed by You shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to
                    the authenticity / genuineness / misrepresentation / fraud / negligence of the information disclosed shall lie on the Company nor will the Company be in any way responsible to verify any information obtained from You.
                </span>
            </p>
            <p className="c10"><span className="c1"></span></p>
            <p className="c13"><span className="c5">Certain features of the Platform may be restricted, unavailable or unusable if You choose not to provide certain information.</span></p>
            <p className="c10"><span className="c1"></span></p>
            <p className="c13"><span className="c5">The above information is collectively referred to as (&ldquo;</span><span className="c4">Personal Information</span><span className="c1">&rdquo;). </span></p>
            <p className="c24 c32"><span className="c1"></span></p>
            <ol className="c14 lst-kix_6rno7yvcjofv-2 start" start={1}>
                <li className="c21 c26 li-bullet-1">
                    <span className="c5 c22">Electronic mail (e-mail) information</span>
                    <span className="c5">: When you link your email to the Platform, We may access such email accounts and collect and securely store Your email account details, including </span><span className="c5">the </span>
                    <span className="c1">
                        e-mails received in Your email account (including any email metadata, etc.). For the sake of clarity, the Company employs automated processes for accessing and analyzing information from Your linked email accounts. You
                        can provide Us access to one or more of Your e-mail accounts by connecting such emails to the Platform.
                    </span>
                </li>
            </ol>
            <p className="c10"><span className="c1"></span></p>
            <ol className="c14 lst-kix_6rno7yvcjofv-2" start={2}>
                <li className="c21 c27 li-bullet-0">
                    <span className="c5 c22">Non &ndash; Personal and Automatic Information</span>
                    <span className="c5">
                        : We may also collect certain non-personal information, such as Your web request, operating system, browser type, URL, aggregate user data, software and hardware attributes, pages You visit, and cookie information, etc.,
                        which will not identify You specifically (&ldquo;
                    </span>
                    <span className="c4">Non-Personal Information</span>
                    <span className="c1">
                        &rdquo;), while You browse, access or use the Platform. We receive and store Non&ndash;Personal Information by the use of data collection devices such as &ldquo;cookies&rdquo; on certain pages of the Platform, in order
                        to help and analyze Our Platform flow, track user trends, measure promotional effectiveness, and promote trust and safety. We offer certain additional features on the Platform that are only available through the use of a
                        &ldquo;cookie&rdquo;. We place both permanent and temporary cookies in Your computer/mobile device&rsquo;s storage drive.<br />
                    </span>
                </li>
            </ol>
            <p className="c12"><span className="c1"></span></p>
            <ol className="c14 lst-kix_6rno7yvcjofv-2" start={3}>
                <li className="c21 c27 li-bullet-0">
                    <span className="c5 c22">User communications</span>
                    <span className="c1">
                        : If You choose to post messages on Our message boards, chat rooms or other message areas or leave feedback, We will collect and store such information You provide to Us. We retain this information as necessary to
                        resolve disputes, provide customer support, respond to queries, and inquiries, and troubleshoot problems and improve the Services. If You send us correspondence, such as emails or letters, or if other users or third
                        parties send us correspondence about Your activities or postings on the Platform, We may collect and retain such information into a file specific to You for responding to Your request and addressing concerns in relation
                        to Your use of the Platform.
                    </span>
                </li>
            </ol>
            <p className="c18"><span className="c1"></span></p>
            <p className="c11">
                <span className="c5">Your Personal Information, Non-Personal Information and other information listed above shall together be referred to as &ldquo;</span><span className="c4">Your Information</span>
                <span className="c1">&rdquo;. </span>
            </p>
            <p className="c2"><span className="c1"></span></p>
            <p className="c11">
                <span className="c1">
                    All information gathered by the Company shall be stored on servers, log files and any other storage system owned by the Company or by third parties in India. Our primary goal in doing so is to provide You a safe, efficient,
                    smooth, and customized experience on the Platform.
                </span>
            </p>
            <p className="c2"><span className="c1"></span></p>
            <p className="c11">
                <span className="c1">
                    The information collected allows Us to provide the Services and features on the Platform, and to customize the Platform to make Your experience safer and easier. More importantly, while doing so, We collect Your Information
                    from You that We consider necessary for achieving this purpose.
                </span>
            </p>
            <p className="c2">
                <span className="c1"><br /></span>
            </p>
            <p className="c11">
                <span className="c5">We also use cookies on the Platform</span>
                <span className="c1">
                    . Most cookies are &ldquo;session cookies,&rdquo; meaning that they are automatically deleted by Your browser at the end of a session. You are always free to decline Our cookies if Your browser permits, although in that
                    case, You may not be able to use certain features or Services being provided on the Platform.
                </span>
            </p>
            <p className="c2"><span className="c1"></span></p>
            <p className="c11">
                <span className="c1">
                    In general, You can browse the Platform without telling Us who You are or revealing any Personal Information about Yourself. In such a case, We will only collect and store the Non &ndash; Personal Information. Once You give
                    us Your Personal Information, You are not anonymous to Us. Wherever possible, while providing the information to Us, We indicate which fields are mandatory and which fields are optional for You. You always have the option to
                    not provide the Personal Information to Us through the Platform by choosing to not use a particular Service, which requires You to provide such information. We may automatically track certain information about You based upon
                    Your behavior on the Platform. We use this information to do internal research on Your demographics, interests, and behavior to better understand, protect and serve You. This information is compiled and analyzed by the
                    Company on an aggregated basis and not individually, in a manner that does not specifically identify You. &nbsp;
                </span>
            </p>
            <p className="c2"><span className="c1"></span></p>
            <p className="c11">
                <span className="c1">
                    We shall be entitled to retain Your Information for such duration as may be required for the purposes specified hereunder and will be used by Us only in accordance with this Privacy Policy. You acknowledge that if We
                    determine that any information You have provided or uploaded violates the terms of this Privacy Policy, We have the right, in our absolute discretion, to delete or destroy Your Information without incurring any liability to
                    You.
                </span>
            </p>
            <p className="c2"><span className="c1"></span></p>
            <p className="c11">
                <span className="c1">
                    You hereby agree, acknowledge and grant Your consent enabling Us to retain any information provided by You for Our compliance under applicable laws including but not limited to the Prevention of the Money Laundering Act,
                    2002 or the Prevention of Money Laundering (Maintenance of Records) Rules, 2005 as amended, modified/ reenacted from time to time and/ or any other guidelines/policies issued by government/ statutory authorities or any
                    regulatory bodies in this regard.
                </span>
            </p>
            <p className="c7 c16"><span className="c1"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0 start" start={2}>
                <li className="c6 li-bullet-0"><span className="c4">Use of information</span><span className="c1">:</span></li>
            </ol>
            <p className="c7 c16"><span className="c1"></span></p>
            <p className="c11"><span className="c1">We use Your Information, for the following: </span></p>
            <p className="c11"><span className="c1">(i) to provide and improve the Services and features on the Platform that You request; </span></p>
            <p className="c11"><span className="c1">(ii) to resolve disputes and troubleshoot problems; </span></p>
            <p className="c11">
                <span className="c1">
                    (iii) to help promote a safe service on the Platform and protect the security and integrity of the Platform, the Services and the users; <br />
                    (iv) to provide an insight into Your email usage pattern(s);
                </span>
            </p>
            <p className="c11"><span className="c1">(v) to calculate and display your interactions with different entities; </span></p>
            <p className="c11"><span className="c1">(vi) to detect, prevent and protect Us from any errors, fraud and other criminal or prohibited activity on the Platform; </span></p>
            <p className="c11"><span className="c1">(vii) to enforce and inform about our terms and conditions; </span></p>
            <p className="c11"><span className="c1">(viii) to process and fulfill Your request for Services or respond to Your comments, and queries on the Platform; </span></p>
            <p className="c11"><span className="c1">(ix) to contact You; and</span></p>
            <p className="c11"><span className="c1">(x) to communicate important notices or changes in the Services provided by the Company, use of the Platform and the terms which govern the relationship between You and Us.</span></p>
            <p className="c18"><span className="c1"></span></p>
            <p className="c11">
                <span className="c1">
                    We may occasionally ask You to complete optional online surveys. These surveys may ask You for Your contact information and demographic information (like zip code, age etc.,). We use this information to tailor Your
                    experience at the Platform, providing You with content that We think You might be interested in and to display content according to Your preferences. We use Your Personal Information to send You promotional emails, however,
                    We will provide You the ability to opt-out of receiving such emails from Us. If You opt out, the Company may still send You non-promotional emails, such as emails about the Services and Your Account on the Platform.
                </span>
            </p>
            <p className="c18 c16"><span className="c1"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0" start={3}>
                <li className="c6 li-bullet-0"><span className="c4">Sharing of Information:</span></li>
            </ol>
            <p className="c11">
                <span className="c5">
                    <br />
                    Access to your Personal Information will be provided to certain of Our employees strictly on a need-to-know basis.
                </span>
                <span className="c1">We do not disclose Your Personal Information to third parties without Your explicit consent. </span>
            </p>
            <p className="c2"><span className="c1"></span></p>
            <p className="c11">
                <span className="c1">
                    The Company may disclose Your Information, to the extent necessary: (i) to comply with applicable laws and to respond to lawful requests and legal process, (ii) to protect the rights and property of the Company, Our users,
                    and others, including to enforce the Terms, and (iii) in an emergency to protect the personal safety and assets of the Company, the users, or any person. In such an event the Company is in no manner responsible for informing
                    You or seeking Your approval or consent.
                </span>
            </p>
            <p className="c2"><span className="c1"></span></p>
            <p className="c11">
                <span className="c1">
                    We may, in compliance with applicable laws, share/transfer/assign all of Your Information with any other business entity(ies), in the event of a merger, sale, re-organization, amalgamation, joint ventures, assignment,
                    restructuring of business or transfer or disposition of all or any portion of Our business.
                </span>
            </p>
            <p className="c7 c16"><span className="c1"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0" start={4}>
                <li className="c6 li-bullet-0"><span className="c4">Security Precautions and Measures:</span></li>
            </ol>
            <p className="c2" id="h.gjdgxs"><span className="c1"></span></p>
            <p className="c11">
                <span className="c5">
                    Our Platform has reasonable security measures and safeguards in place to protect Your privacy and Personal Information from loss, misuse, unauthorized access, disclosure, destruction, and alteration of the information in
                    compliance with applicable laws. Further, whenever You change or access Your Account on the Platform or any information relating to it, We offer the use of a secure server.
                </span>
                <span className="c1">
                    We cannot however ensure or warrant the security of any information You transmit to the Company or guarantee that Your Information provided for availing the Services or Platform may not be accessed, disclosed, altered, or
                    destroyed by a breach of any of Our security measures and safeguards. It is further clarified that You have and so long as You access and/or use the Platform (directly or indirectly) the obligation to ensure that You shall
                    at all times take adequate physical, managerial, and technical safeguards, at Your end, to preserve the integrity and security of Your data which shall include and not be limited to Your Personal Information.
                </span>
            </p>
            <p className="c2"><span className="c1"></span></p>
            <p className="c11">
                <span className="c1">
                    This Privacy Policy and the security controls and practices implemented by the Company to protect Your Personal Information shall be the reasonable security practices and procedures under section 43A of the Information
                    Technology Act, 2000.
                </span>
            </p>
            <p className="c18"><span className="c1"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0" start={5}>
                <li className="c6 li-bullet-0"><span className="c8 c4">Links to Other Third &ndash; party Sites and collection of information: </span></li>
            </ol>
            <p className="c18 c16"><span className="c1"></span></p>
            <p className="c11">
                <span className="c5">Our Platform may provide links to other third - party Platforms (&ldquo;</span><span className="c4">Third &ndash; Party Sites</span>
                <span className="c1">
                    &rdquo;) that may collect Your Personal Information including Your IP Address, browser specification, or operating system. The Company is not in any manner responsible for the security of such information or their privacy
                    practices or content of those third&ndash;party sites. Additionally, You may also encounter &ldquo;cookies&rdquo; or other similar devices on certain pages of the Third &ndash; Party Sites and it is hereby clarified by the
                    Company that it does not control the use of cookies by these Third &ndash; Party Sites. These third-party service providers and Third-Party Sites may have their own privacy policies governing the storage and retention of
                    Your Information that You may be subject to. This Privacy Policy does not govern any information provided to, stored on, or used by these third-party providers and Third-Party Sites. We recommend that when You enter a
                    Third-Party Site, You review the Third-Party Site&rsquo;s privacy policy as it relates to safeguarding of Your Information. You agree and acknowledge that We are not liable for the information published in search results or
                    by any Third-Party Sites.
                </span>
            </p>
            <p className="c7 c16"><span className="c1"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0" start={6}>
                <li className="c6 li-bullet-0"><span className="c8 c4">Manage access to your Gmail Account</span></li>
            </ol>
            <p className="c2 c20"><span className="c8 c4"></span></p>
            <p className="c11 c20">
                <span className="c5">
                    You can manage access to your Gmail account and review the type of account access a third party has as well as the Google services it has access to, by going to the Security section of your Google Account. To learn more
                    about how Google helps users share their data safely, please go through the article here:
                </span>
                <span className="c0">
                    <a className="c9" href="https://www.google.com/url?q=https://support.google.com/accounts/answer/10130420?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1663078423093745&amp;usg=AOvVaw0_42heP8DdqmNagh3EO65P">
                        https://support.google.com/accounts/answer/10130420?hl=en
                    </a>
                </span>
            </p>
            <p className="c2 c20"><span className="c8 c4"></span></p>
            <p className="c11 c20">
                <span className="c1">
                    Email Ninja&rsquo;s usage and transfer of information received from Google APIs will comply with and adhere to <a className="c9-custom" target="_blank" href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>
                    , including the Limited Use requirements. In order to comply with the Google Policy, we will only use the
                    data that we obtained from the restricted Gmail APIs for the specific &ldquo;Limited Uses&rdquo; as permitted by the Google Policy
                </span>
            </p>
            <p className="c2 c20"><span className="c1"></span></p>
            <p className="c2 c20"><span className="c8 c4"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0" start={7}>
                <li className="c6 li-bullet-0"><span className="c4">Public Posts: </span></li>
            </ol>
            <p className="c7 c16"><span className="c1"></span></p>
            <p className="c11 c20">
                <span className="c5">You may provide Your feedback, reviews, testimonials, etc. on the Platform on Your use of the Services provided by the Company (&ldquo;</span><span className="c4">Posts</span>
                <span className="c5">&rdquo;). </span>
                <span className="c5">Any content or Personal Information and Posts that You share or upload on the publicly viewable portion of the Platform will be publicly available, and can be viewed by other users. </span>
                <span className="c1">
                    Your Posts shall have to comply with the conditions relating to use of Platform as mentioned in the Terms. The Company retains an unconditional right to remove and delete any Post or such part of the Post that, in the
                    opinion of the Company, does not comply with the conditions in the Terms. The Company reserves the right to use, reproduce and share Your Posts for any purpose. If You delete Your Posts from the Platform, copies of such
                    Posts may remain viewable in archived pages, or such Posts may have been copied or stored by other Users.
                </span>
            </p>
            <p className="c7 c16"><span className="c1"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0" start={8}>
                <li className="c6 li-bullet-0"><span className="c4">Your Consent and Changes to Privacy Policy:</span></li>
            </ol>
            <p className="c7 c16"><span className="c1"></span></p>
            <p className="c11 c20">
                <span className="c4">Withdrawal of consent</span>
                <span className="c1">
                    : You may choose to withdraw Your consent provided hereunder at any point in time. Such withdrawal of the consent must be sent in writing to support@emailninja.tech. In case You do not provide Your consent or later withdraw
                    Your consent, We request You not to access the Platform or use the Services and also reserve the right to not provide You any Services on the Platform. In such a scenario, the Company may delete Your Information or
                    de-identify it so that it is anonymous and not attributable to You. In the event, the Company retains the Personal Information post withdrawal or cancellation of Your consent, it shall retain it only for the period permitted
                    under applicable laws.
                </span>
            </p>
            <p className="c2 c20"><span className="c1"></span></p>
            <p className="c11 c20">
                <span className="c4">Correcting inaccuracies in the information: </span>
                <span className="c1">
                    You may correct or update any information online. In the event of loss of access details, You may retrieve the same or receive new access details by sending an email to: support@emailninja.tech. &nbsp;
                </span>
            </p>
            <p className="c2 c20"><span className="c8 c4"></span></p>
            <p className="c11 c20">
                <span className="c4">Right to Erasure</span>
                <span className="c1">
                    : &nbsp;This is also known as &lsquo;the right to be forgotten&rsquo; and, in simple terms, enables You to request the deletion or removal of Your Information where there&rsquo;s no compelling reason for Us to keep it. Such
                    right of erasure can be exercised by writing to Us at support@emailninja.tech. This is not a general right to erasure; there are exceptions, such as when the law requires Us to retain certain Personal Information about You
                    or where such retention is required for providing the Services to You, etc.
                </span>
            </p>
            <p className="c2 c20"><span className="c4 c8"></span></p>
            <p className="c11 c20" id="h.30j0zll">
                <span className="c4">Changes To Our Privacy Policy</span><span className="c5">:</span><span className="c4">&nbsp;</span>
                <span className="c1">
                    We reserve the unconditional right to change, modify, add, or remove portions of this Privacy Policy at any time, by notifying You of such changes. Any changes or updates will be effective immediately. You should review this
                    Privacy Policy regularly for changes. You can determine if changes have been made by checking the &ldquo;Last Updated&rdquo; legend above.
                </span>
            </p>
            <p className="c7 c16"><span className="c1"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0" start={9}>
                <li className="c6 li-bullet-0"><span className="c8 c4">Data Retention and Deletion</span></li>
            </ol>
            <p className="c7 c16"><span className="c1"></span></p>
            <p className="c11 c20">
                <span className="c1">
                    We shall be entitled to retain Your Information for such duration as may be required for the purposes specified hereunder or for such other periods as may be required under the applicable laws and the said information will
                    be used by Us only in accordance with this Privacy Policy. &nbsp;However, We may retain Your Information if we believe it may be necessary to prevent fraud or future abuse, to enable Us to exercise our legal rights and/or
                    defend against legal claims or if required by law or for other legitimate purposes. When the retention period for Your Personal Information as outlined above expires, We will actively destroy/delete such Personal Information
                    from our systems. We may, however, continue to retain Your Personal Information in anonymized form for analytical and research purposes.
                </span>
            </p>
            <p className="c7 c16"><span className="c1"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0" start={10}>
                <li className="c6 li-bullet-0"><span className="c4">Grievance Officer:</span></li>
            </ol>
            <p className="c7 c16"><span className="c1"></span></p>
            <p className="c11 c20"><span className="c1">If You have any grievance with respect to the Platform or the Services, You may write to the grievance officer, the name and contact details have been provided below: </span></p>
            <p className="c7"><span className="c1"></span></p>
            <p className="c11 c20">
                <span className="c4">Name</span><span className="c5">: </span><span className="c22 c30"><a className="c9" href="mailto:sugath@flash.tech">Sugath Surendran</a></span>
            </p>
            <p className="c11 c20"><span className="c4">Email</span><span className="c1">: support@emailninja.tech</span></p>
            <p className="c7 c16"><span className="c1"></span></p>
            <ol className="c14 lst-kix_yzq87a9m9v8u-0" start={11}>
                <li className="c6 li-bullet-0"><span className="c4">Questions</span><span className="c1">?</span></li>
            </ol>
            <p className="c7 c16"><span className="c1"></span></p>
            <p className="c11 c20"><span className="c5">Please feel free to contact us at support@emailninja.tech regarding any questions on the Privacy Policy. </span></p>
            <p className="c31"><span className="c8 c19"></span></p>
        </div>
    </Box>

  );
};

export default PrivacyPolicy;
