import { CSSProperties } from "@mui/styled-engine";
import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { FC, Fragment, useState } from "react";
import { Outlet } from "react-router";
import { Box, styled } from "@mui/material";

import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import Footer from "page-sections/Home/Footer";

const Wrapper = styled(Box)(({ theme }) => ({
  paddingLeft: "3rem",
  paddingRight: "3rem",
  height: "100%",

  [theme.breakpoints.down(1200)]: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
    height: "100%",
  },
}));

const DashboardLayout: FC = ({ children, ...props }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  // dashboard body wrapper custom style
  const customStyle: CSSProperties = {
    width: `calc(100% - ${showSideBar ? "260px" : "0px"})`,
    marginLeft: showSideBar ? "260px" : "0px",
    minHeight: 400,
  };

  return (
    <Fragment>
      <DashboardSidebar
        showSideBar={showSideBar}
        showMobileSideBar={showMobileSideBar}
        closeMobileSideBar={() => setShowMobileSideBar(false)}
      />
      <Box
        minHeight="100%"
        justifyContent="space-between"
        flexDirection="column"
        display="flex"
      >
        <LayoutBodyWrapper sx={customStyle}>
          <DashboardHeader
            setShowSideBar={() => setShowSideBar((state) => !state)}
            setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
          />

          <Wrapper>{children || <Outlet />}</Wrapper>
        </LayoutBodyWrapper>
        <Footer />
      </Box>
    </Fragment>
  );
};

export default DashboardLayout;
