import {
  AppBar,
  Box,
  // ClickAwayListener,
  IconButton,
  styled,
  Theme,
  Toolbar,
  useMediaQuery,
  Button,
  useTheme,
} from "@mui/material";
import { SettingsContext } from "contexts/settingsContext";
import { Span } from "components/Typography";
// import MenuLeft from "icons/MenuLeft";
// import MenuLeftRight from "icons/MenuLeftRight";
// import SearchIcon from "icons/SearchIcon";
import ThemeIcon from "icons/ThemeIcon";
import { FC, Fragment, useContext } from "react";
import { themeSettingsTypes } from "theme";
import FlexBox from "components/flexbox/FlexBox";
// import useEmailAnalytics from "hooks/useEmailAnalytics";

// import LanguagePopover from "./popovers/LanguagePopover";
// import NotificationsPopover from "./popovers/NotificationsPopover";
// import ProfilePopover from "./popovers/ProfilePopover";
import EmailDetails from "./popovers/EmailDetails";
// import ServicePopover from "./popovers/ServicePopover";
// import SearchBar from "./SearchBar";
// import { connectGmail } from "contexts/EmailAnalytics/actions";
// ------------------------------------------------
type DashboardHeaderProps = {
  setShowSideBar: () => void;
  setShowMobileSideBar: () => void;
  isThemeDisable?: boolean;
};
// ------------------------------------------------

// custom styled components
const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: theme.palette.primary.brandColor,
  color: theme.palette.text.primary,
}));

const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": { backgroundColor: theme.palette.action.hover },
}));

// const ToggleIcon = styled(Box)<{ width?: number }>(({ theme, width }) => ({
//   height: 3,
//   margin: "5px",
//   marginLeft: 0,
//   width: width || 25,
//   borderRadius: "10px",
//   transition: "width 0.3s",
//   backgroundColor: theme.palette.primary.main,
// }));
const StyledLogo = styled(Box)(() => ({
  paddingLeft: 8,
  fontWeight: 700,
  fontSize: 20,
}));

const DashboardHeader: FC<DashboardHeaderProps> = (props) => {
  // const { setShowMobileSideBar } = props;
  // const theme = useTheme();
  // const dispatch = useDispatch();
  // const [openSearchBar, setSearchBar] = useState(false);
  const { settings, saveSettings } = useContext(SettingsContext);
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  // const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down(1200));
  // const { userReducer } = useEmailAnalytics();

  // const handleChangeDirection = (value: "ltr" | "rtl") => {
  //   saveSettings({ ...settings, direction: value } as themeSettingsTypes);
  // };
  const handleChangeTheme = (value: "light" | "dark") => {
    saveSettings({ ...settings, theme: value } as themeSettingsTypes);
  };

  // const connectGmailAction = () => {
  //   connectGmail(dispatch);
  // };

  // useEffect(() => {
  //   if (userReducer?.authUrl) {
  //     window.open(
  //       userReducer?.authUrl as string,
  //       "_blank" // <- This is what makes it open in a new window.
  //     );
  //   }
  // }, [userReducer?.authUrl]);

  return (
    <DashboardHeaderRoot position="sticky">
      <StyledToolBar>
        <FlexBox ml={1.5}>
          <img src="/static/logo/header-logo.png" alt="logo" />
        </FlexBox>
        {/* {downMd && (
          <Box sx={{ cursor: "pointer" }} onClick={setShowMobileSideBar}>
            <ToggleIcon />
            <ToggleIcon width={18} />
            <ToggleIcon width={9} />
          </Box>
        )} */}

        {/* <ClickAwayListener onClickAway={() => setSearchBar(false)}>
          <Box>
            {!openSearchBar && (
              <StyledIconButton onClick={() => setSearchBar(true)}>
                <SearchIcon sx={{ color: "text.disabled" }} />
              </StyledIconButton>
            )}

            <SearchBar
              open={openSearchBar}
              handleClose={() => setSearchBar(false)}
            />
          </Box>
        </ClickAwayListener> */}

        <Box flexGrow={1} ml={1} />

        {/* {settings.direction === "rtl" ? (
          <StyledIconButton onClick={() => handleChangeDirection("ltr")}>
            <MenuLeft sx={{ color: "text.disabled" }} />
          </StyledIconButton>
        ) : (
          <StyledIconButton onClick={() => handleChangeDirection("rtl")}>
            <MenuLeftRight sx={{ color: "text.disabled" }} />
          </StyledIconButton>
        )} */}

        {/* <Button
          onClick={connectGmailAction}
          variant="outlined"
          color="info"
          sx={{
            height: "30px",
            bgcolor: "info.main",
            borderRadius: "0 0 5px 5px",
            "&:hover": { bgcolor: "info.main" },
          }}
        >
          <Span color={theme.palette.common.white}>Connect Gmail</Span>
        </Button> */}
        <EmailDetails />
        {props?.isThemeDisable ? null : settings.theme === "light" ? (
          <Box pr={1}>
            <StyledIconButton onClick={() => handleChangeTheme("dark")}>
              <ThemeIcon />
            </StyledIconButton>
          </Box>
        ) : (
          <Box pr={1}>
            <StyledIconButton onClick={() => handleChangeTheme("light")}>
              <ThemeIcon />
            </StyledIconButton>
          </Box>
        )}

        {upSm && (
          <Fragment>
            {/* <LanguagePopover />
            <NotificationsPopover />
            <ServicePopover /> */}
          </Fragment>
        )}
        {/* <ProfilePopover /> */}
      </StyledToolBar>
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;
