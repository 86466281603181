export const COOKIES = {
  session: "sn",
};

export const COVERAGE = {
  PROCESSING: "PROCESSING",
  FULL: "FULL",
  PARTIAL: "PARTIAL",
};

export const EMAIL_FILTER_TYPE = {
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  HALF_YEARLY: "HALF_YEARLY",
  YEARLY: "YEARLY",
};
