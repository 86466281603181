import userReducer, { UserState, userInitialState } from "./user";
import dashboardReducer, {
  DashboardState,
  dashboardInitialState,
} from "./dashboard";

export type InitialAuthState = {
  userReducer: UserState;
  dashboardReducer: DashboardState;
};
export const initialState: InitialAuthState = {
  userReducer: userInitialState,
  dashboardReducer: dashboardInitialState,
};

const combineReducers = (slices: any) => (state: any, action: any) =>
  Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state
  );

export const rootReducer = combineReducers({ userReducer, dashboardReducer });

export default rootReducer;
