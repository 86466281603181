import {
  ButtonBase,
  styled,
  useMediaQuery,
  Theme,
  useTheme,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Small } from "components/Typography";
import { FC } from "react";
import useEmailAnalytics from "hooks/useEmailAnalytics";

import useStyles from './styles'

// styled components
const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  padding: 5,
  marginLeft: 4,
  borderRadius: 30,
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": { backgroundColor: theme.palette.action.hover },
}));


const EmailDetails: FC = () => {
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const theme = useTheme();
  const css = useStyles()
  const { dashboardReducer: { profile } } = useEmailAnalytics();
  if (profile?.email) {
    return (
      <StyledButtonBase>
        {upSm && (
          <Small mx={1} color="text.secondary">
            <Small
              fontWeight="500"
              display="inline"
              color={theme.palette.common.white}
            >
              {profile.email || ""}
            </Small>
          </Small>
        )}
        <AccountCircleIcon className={css.icon} />
      </StyledButtonBase>
    );
  }

  return null;
};

export default EmailDetails;
