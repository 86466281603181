import { toLower, startCase } from "lodash";
const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

interface SorterMap {
  [key: string]: number;
}

const sorter: SorterMap = {
  sunday: 0, // << if sunday is first day of week
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  // "sunday": 7
};

export const mapClassificationData = (data: any) => {
  const labels: string[] = [];
  const dataSet: any[] = [];
  let totalEmailsValue = 0;
  if (data) {
    const { categories, totalEmails } = data;
    categories?.map((item: any) => {
      labels.push(item.domainDisplayName as string);
      totalEmailsValue += +item.value;
      dataSet.push(+((+item.value * 100) / +totalEmails).toFixed(2));
      return item;
    });
    if (totalEmailsValue < totalEmails) {
      labels.push("Unclassified");
      dataSet.push(+(((+totalEmails - totalEmailsValue) * 100) / +totalEmails).toFixed(2))
    }
  }
  return {
    dataSet,
    labels,
  };
};

export const mapBusiestHourData = (busiestHour: any) => {
  const categories: string[] = [];
  const sendData: string[] = [];
  const recieveData: string[] = [];
  let max = 0;
  let maxCount = 0;
  let busiestHourOfDay = "";

  if (busiestHour && Array.isArray(busiestHour)) {
    busiestHour?.map((item: any) => {
      categories.push(item.hour);
      sendData.push(item.sent);
      recieveData.push(item.received);

      if (maxCount < item.sent + item.received) {
        maxCount = item.sent + item.received;
        busiestHourOfDay = `${tConvert(item.hour)} - ${tConvert(
          item.hour + 1
        )}`;
      }

      if (max < item.sent) {
        max = item.sent;
      }
      if (max < item.received) {
        max = item.received;
      }
      return item;
    });
  }

  return {
    chartSeries: [
      { name: "Sent", data: sendData },
      { name: "Received", data: recieveData },
    ],
    categories,
    max,
    busiestHourOfDay,
  };
};

// convet 0 to 23 hour into AM/PM time
export const tConvert = (hour: number) => {
  if (hour === 0 || hour === 24) {
    return "12 AM";
  } else if (hour === 12) {
    return "12 PM";
  } else if (hour < 12) {
    return `${String(hour).padStart(2, "0")} AM`;
  } else {
    return `${hour - 12} PM`;
  }
};

export const mapBusiestDayData = (busiestDay: any) => {
  const dataSent: number[] = [];
  const dataReceived: number[] = [];
  let max = 0;
  let maxCount = 0;
  let busiestDayOfWeek = "";

  if (busiestDay && Array.isArray(busiestDay)) {
    // Sorting based on day
    busiestDay?.sort((a: any, b: any) => {
      let day1 = a.day.toLowerCase() as string;
      let day2 = b.day.toLowerCase() as string;
      return sorter[day1] - sorter[day2];
    });

    busiestDay?.map((item: any, index: number) => {
      dataSent.push(item.sent);
      dataReceived.push(item.received);

      if (maxCount < item.sent + item.received) {
        maxCount = item.sent + item.received;
        busiestDayOfWeek = days[index];
      }

      if (max < item.sent) {
        max = item.sent;
      }
      if (max < item.received) {
        max = item.received;
      }
      return item;
    });
  }
  return {
    dataSent,
    dataReceived,
    categories: days,
    max,
    busiestDayOfWeek,
  };
};

export const getAroundTime = (totalEmails: number) => {
  const minutesTo = totalEmails * 2.5;
  const minutesTill = totalEmails * 3.3;

  if (minutesTo < 60) {
    // Less than 1 hour
    return `${Math.ceil(minutesTo)} - ${Math.ceil(minutesTill)} minutes`;
  } else if (minutesTo < 1440) {
    // Less than 24 hours (1 day = 1440 minutes)
    const hoursTo = Math.floor(minutesTo / 60);
    const hoursTill = Math.ceil(minutesTill / 60); // Use Math.ceil for upper bound
    return `${hoursTo} - ${hoursTill} hours`;
  } else if (minutesTo < 10080) {
    // Less than 7 days (1 week = 10080 minutes)
    const daysTo = Math.floor(minutesTo / 1440);
    const daysTill = Math.ceil(minutesTill / 1440); // Use Math.ceil for upper bound
    return `${daysTo} - ${daysTill} days`;
  } else if (minutesTo < 43200) {
    // Less than 30 days (1 month = 43200 minutes)
    const weeksTo = Math.floor(minutesTo / 10080);
    const weeksTill = Math.ceil(minutesTill / 10080); // Use Math.ceil for upper bound
    return `${weeksTo} - ${weeksTill} weeks`;
  } else if (minutesTo < 525600) {
    // Less than 365 days (1 year = 525600 minutes)
    const monthsTo = Math.floor(minutesTo / 43200);
    const monthsTill = Math.ceil(minutesTill / 43200); // Use Math.ceil for upper bound
    return `${monthsTo} - ${monthsTill} months`;
  } else {
    // More than or equal to 1 year
    const yearsTo = Math.floor(minutesTo / 525600);
    const yearsTill = Math.ceil(minutesTill / 525600); // Use Math.ceil for upper bound
    return `${yearsTo} - ${yearsTill} years`;
  }
};

