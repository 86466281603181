import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
  },
}));

export default useStyles;
