import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: "14px !important",
    width: "14px !important",
    fontWeight: "bold",
    marginLeft: 4,
  },
  emailAddress: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap !important",
      overflow: "hidden !important",
      textOverflow: "ellipsis !important",
    },
  },
  link: {
    fontSize: 14,
    color: "white",
    fontWeight: 600,
  },
  copyright: {
    fontSize: 14,
    color: "#ccc",
    fontWeight: 500,
  },
  valueDetails: {
    color: "#61A9FF !important",
    fontSize: "18px !important",
    fontWeight: "bold !important",
  },
  titleDetails: {
    color: theme.palette.text.secondary,
    fontSize: "14px !important",
    fontWeight: "normal !important",
    fontFamily: "neue_haas_grotesk_disp55roman !important",
  },
  graphTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    paddingLeft: 18,
    "&>h5": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  illustration: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileStatsView: {
    alignItems: "center",
    display: "flex",
    "&>.row": {
      "&>span": {
        fontSize: "0.6rem",
        fontWeight: "lighter",
        "&>.value": {
          fontFamily: "neue_haas_grotesk_disp55roman",
        },
      },
    },
  },
}));

export default useStyles;
