import { Box } from "@mui/system";
import "./style.scss";

export const TermsOfUse = () => {
  return (
    <Box
      width="100%"
      justifyContent="center"
      display="flex"
      className="terms-content"
    >
      <div className="c19 c29 doc-content">
        <p className="c14">
          <span className="c0">Last updated on June 07, 2024</span>
        </p>
        <p className="c10">
          <span className="c1 c21"></span>
        </p>
        <p className="c28">
          <span className="c1 c21">Terms of Use</span>
        </p>
        <p className="c10">
          <span className="c1 c21"></span>
        </p>
        <p className="c14">
          <span className="c16">These terms of use (&ldquo;</span>
          <span className="c8">Terms</span>
          <span className="c16">
            &rdquo;) mandate the terms on which the users (&ldquo;
          </span>
          <span className="c8">You</span>
          <span className="c16">&rdquo; or &ldquo;</span>
          <span className="c8">Your</span>
          <span className="c16">&rdquo; or &ldquo;</span>
          <span className="c8">Yourself</span>
          <span className="c16">&rdquo; or &ldquo;</span>
          <span className="c8">User</span>
          <span className="c16">
            &rdquo;) access and register on the website https://flash.tech and
            website application &lsquo;Email Ninja&rsquo; at
            https://emailninja.tech owned and operated by{" "}
          </span>
          <span className="c8">Flashmonk Private Limited</span>
          <span className="c16">&nbsp;(&ldquo;</span>
          <span className="c8">Company</span>
          <span className="c16">
            &rdquo;), collectively referred to as (&ldquo;
          </span>
          <span className="c8">the Platform</span>
          <span className="c0">&rdquo;).</span>
        </p>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <p className="c14">
          <span className="c0">
            These Terms are an electronic record in terms of the Information
            Technology Act, 2000 and rules made thereunder as applicable and are
            generated by a computer system and does not require any physical,
            electronic, or digital signatures.
          </span>
        </p>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <p className="c14">
          <span className="c16">
            Please read these Terms and the Privacy Policy carefully before
            using or registering on the Platform or accessing any material,
            information, etc., through the Platform. By clicking the &ldquo;
          </span>
          <span className="c16 c26">Connect Gmail&rdquo; </span>
          <span className="c0">
            button provided at the welcome page for the Platform, You accept
            these Terms, the Privacy Policy and agree to be legally bound by the
            same.{" "}
          </span>
        </p>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <p className="c14">
          <span className="c16">
            The Company retains an unconditional right to modify or amend these
            Terms and the Privacy Policy and notify You of the same. You can
            determine when these Terms were last modified by referring to the
            &ldquo;Last Updated&rdquo; legend above. It shall be Your
            responsibility to check the Terms periodically for changes. Your
            acceptance of the amended Terms shall signify Your consent to such
            changes and agreement to be legally bound by the same. Further, by
            furnishing Your Personal Information to the Company or giving Your
            consent to the Company to access Your Personal Information for the
            purpose of rendering the Services{" "}
          </span>
          <span className="c16 c26">(as defined below)</span>
          <span className="c0">
            , You also agree that You are interested in availing the Services
            through the Platform in accordance with these Terms.
          </span>
        </p>
        <p className="c3">
          <span className="c1 c21"></span>
        </p>
        <ol className="c4 lst-kix_hyo15tavnzmq-0 start" start="1">
          <li className="c2 li-bullet-0">
            <span className="c1">Services</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0">
            <br />
          </span>
        </p>
        <ol className="c4 lst-kix_xlfsbi2sm0px-1 start" start="1">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You acknowledge that the Platform allows You to avail the
              following services including:
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_3i7tngtwyogn-0 start" start="1">
          <li className="c12 li-bullet-1">
            <span className="c8">License to the Platform</span>
            <span className="c16">
              : The Company provides You a limited, non&ndash;exclusive,
              non&ndash;transferable, royalty free license to use the Platform
              for the purposes of registering on the Platform, accessing the
              Content (
            </span>
            <span className="c16 c26">as defined below</span>
            <span className="c0">
              ) and availing the Services through the Platform.
            </span>
          </li>
        </ol>
        <p className="c10 c20">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_3i7tngtwyogn-0" start="2">
          <li className="c12 li-bullet-2">
            <span className="c8">Seamless order tracking: </span>
            <span className="c0">
              You will be able to see Your current order status, expected
              delivery date, etc., in a consolidated manner on the Platform.
            </span>
          </li>
        </ol>
        <p className="c10 c25">
          <span className="c17 c8"></span>
        </p>
        <ol className="c4 lst-kix_3i7tngtwyogn-0" start="3">
          <li className="c12 li-bullet-1">
            <span className="c8">
              Insight on the online shopping spendings:{" "}
            </span>
            <span className="c0">
              Company will track and consolidate Your spendings details in terms
              of amount per order, types of goods or services availed, spends
              during a month, preferred websites etc. Such details will be
              collectively displayed on the Platform.
            </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c16">The aforementioned services </span>
          <span className="c16 c19">shall be collectively referred to as</span>
          <span className="c16">&nbsp;the &ldquo;</span>
          <span className="c8">Services</span>
          <span className="c0">
            &rdquo;. The Company may, at its discretion, add, modify, or remove
            any of the Services listed above from time to time. &nbsp;
          </span>
        </p>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_nh1bdi7ymzg-1 start" start="1">
          <li className="c2 li-bullet-0">
            <span className="c1">Provision of Services:</span>
            <span className="c0">
              &nbsp;You agree and acknowledge that the Company only facilitates
              availing of the Services on the Platform. The Services availed by
              You through the Platform are separate from the services or
              products availed by You from third party service providers. You
              acknowledge that under no circumstances should You expect the
              Company to be responsible for any deficiency or failure in the
              services or products provided to you by the third-party service
              providers. The services and products provided by third party
              service providers may be subject to separate terms and conditions
              or such other agreement between You and the concerned service
              provider(s), You agree and acknowledge that the Company is neither
              a party to such terms nor will it be liable thereunder for any
              reason whatsoever. &nbsp;The Company will club the information
              about the products or services of different service providers and
              list them in a consolidated manner on its Platform for the benefit
              of the Users.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_xlfsbi2sm0px-1" start="2">
          <li className="c2 li-bullet-0">
            <span className="c0">
              As part of the Services provided to You, You agree to provide
              honest feedback/review about Our Services, if required by the
              Company.
            </span>
          </li>
        </ol>
        <p className="c5 c18">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_xlfsbi2sm0px-1" start="3">
          <li className="c2 li-bullet-0">
            <span className="c0">
              The Company does not: (i) adopt any &lsquo;unfair trade
              practices&rsquo; either on the Platform or otherwise with respect
              to the Services; and (ii) discriminate between Users of the same
              class or makes any arbitrarily classification of the Users.{" "}
            </span>
          </li>
        </ol>
        <p className="c7">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-0 start" start="2">
          <li className="c2 li-bullet-0">
            <span className="c1">Eligibility to Use </span>
          </li>
        </ol>
        <p className="c10 c13">
          <span className="c1 c21"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1 start" start="1">
          <li className="c2 li-bullet-0">
            <span className="c0">
              The Use and access to the Platform is available to all persons who
              can be legally bound by contract and who are not disqualified or
              declared incompetent under the Indian Contract Act, 1872. You
              hereby represent that You are legally qualified to enter a binding
              contract, are not a person barred from receiving Services from the
              Platform under the applicable laws or by a regulatory authority,
              are a resident Indian as per the Indian foreign exchange and tax
              laws and are competent to enter into a binding contract.
            </span>
          </li>
        </ol>
        <p className="c14 c23">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="2">
          <li className="c2 li-bullet-0">
            <span className="c0">
              We maintain the right to provide Services to only those Users who
              are &ndash; a) competent to enter into legally binding contracts,
              b) have made the representations as provided above, c) and also
              qualify under the internal policy(ies) for the same determined
              solely by Us from time to time. We shall have the sole right to
              change, modify, add, or remove, in whole or in part, internal
              policy(ies), in relation to the provision of the Services at any
              time by providing a prior written notice or intimation to the
              Users.
            </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="3">
          <li className="c2 li-bullet-0">
            <span className="c16">
              You shall not have more than one active Account (
            </span>
            <span className="c16 c26">as defined below</span>
            <span className="c0">
              ) on the Platform. Additionally, You are prohibited from selling,
              trading, or otherwise transferring Your Account to another party
              or impersonating any other person for the purpose of creating an
              Account on the Platform.
            </span>
          </li>
        </ol>
        <p className="c9">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-0" start="3">
          <li className="c2 li-bullet-0">
            <span className="c1">User Account, Password and Security </span>
          </li>
        </ol>
        <p className="c10 c22">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="4">
          <li className="c2 li-bullet-0">
            <span className="c16">
              You may browse certain sections of the Platform without being
              registered with Us. However, to avail the Services on the
              Platform, You are required to set up an account on the Platform by
              providing such information as may be required by the Company as
              provided under the Privacy Policy (&ldquo;
            </span>
            <span className="c8">Account</span>
            <span className="c16">
              &rdquo;). You can access the Platform by logging into Your Account
              with Your credentials.{" "}
            </span>
          </li>
        </ol>
        <p className="c5">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="5">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You shall ensure and confirm that the Account information provided
              by You is complete, accurate and up-to-date. If there is any
              change in the Account information, You shall promptly update Your
              Account information on the Platform.{" "}
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="6">
          <li className="c2 li-bullet-0">
            <span className="c0">
              If You provide any information that is untrue, inaccurate, not
              current or incomplete (or becomes untrue, inaccurate, not current
              or incomplete), or if the Company has reasonable grounds to
              suspect that such information is untrue, inaccurate, not current
              or incomplete, the Company has the right to refuse or reject the
              provision of Services.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="7">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You will be responsible for maintaining the confidentiality of the
              Account information and are fully responsible for all activities
              that occur under Your Account. You agree to immediately notify the
              Company of any unauthorized use of Your Account information or any
              other breach of security. The Company cannot and will not be
              liable for any loss or damage arising from Your failure to comply
              with this provision. You may be held liable for losses incurred by
              the Company or any other user of or visitor to the Platform due to
              authorized access or unauthorized use of Your Account as a result
              of Your failure in keeping Your Account information secure and
              confidential.{" "}
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="8">
          <li className="c2 li-bullet-0">
            <span className="c16">
              You hereby acknowledge and agree that the deletion of the Platform
              from Your device does not constitute termination of Your Account
              and agree to undertake the process detailed herein in order to
              complete the de-registration of Your Account (&ldquo;
            </span>
            <span className="c8">De-registration</span>
            <span className="c0">
              &rdquo;). If and when You are desirous of having Your name and
              other details removed from Our records, immediately upon receiving
              Your written request to that effect at support@emailninja.tech,
              We shall remove and/delete all such information. However, we may
              retain certain information regarding Your Account, even after
              receiving a deletion request from You, where such retention is
              required under applicable laws. You hereby acknowledge that the
              removal of Your details from the Platform does not constitute
              termination of Your outstanding obligations, if any, to the
              Company.{" "}
            </span>
          </li>
        </ol>
        <p className="c9">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-0" start="4">
          <li className="c2 li-bullet-0">
            <span className="c1">Use of the Platform </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="9">
          <li className="c2 li-bullet-0">
            <span className="c0">
              Subject to compliance with these Terms, the Company hereby grants
              You a non-exclusive, limited privilege to access and use the
              Platform. You agree to use the Platform only: (a) for purposes
              that are permitted by the Terms; and (b) in accordance with any
              applicable law, regulation or generally accepted practices or
              guidelines. You agree not to engage in activities that may
              adversely affect the use of the Platform by the Company or other
              Users.
            </span>
          </li>
        </ol>
        <p className="c5">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="10">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You agree not to access (or attempt to access) the Platform by any
              means other than through the interface that is provided by the
              Company. You shall not use any deep-link, robot, spider or other
              automatic device, program, algorithm or methodology, or any
              similar or equivalent manual process, to access, acquire, copy or
              monitor any portion of the Platform or Content, or in any way
              reproduce or circumvent the navigational structure or presentation
              of the Platform, materials or any Content, to obtain or attempt to
              obtain any materials, documents or information through any means
              not specifically made available through the Platform.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="11">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You acknowledge and agree that by accessing or using the Platform,
              You may be exposed to content from others that You may consider
              offensive, indecent, or otherwise objectionable. The Company
              disclaims all liabilities arising in relation to such offensive
              content on the Platform.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="12">
          <li className="c2 li-bullet-0">
            <span className="c0">
              Further, You undertake not to host, display, upload, modify,
              publish, transmit, store, update or share any information that:
            </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_nz0yms2x52g4-0 start" start="1">
          <li className="c6 li-bullet-0">
            <span className="c0">
              belongs to another person and to which the User does not have any
              right;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              is defamatory, obscene, pornographic, pedophilic, invasive of
              another&#39;s privacy, including bodily privacy, insulting, or
              harassing on the basis of gender, libelous, racially, or
              ethnically objectionable, relating or encouraging money laundering
              or gambling, or otherwise inconsistent with or contrary to the
              laws in force;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">is harmful to child;</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              infringes any patent, trademark, copyright, or other proprietary
              rights;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              violates any law for the time being in force;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              deceives or misleads the addressee about the origin of the message
              or knowingly and intentionally communicates any information which
              is patently false or misleading in nature but may reasonably be
              perceived as a fact;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">impersonates another person;</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              threatens the unity, integrity, defense, security or sovereignty
              of India, friendly relations with foreign States, or public order,
              or causes incitement to the commission of any cognisable offense
              or prevents investigation of any offense or is insulting other
              nation;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              contains software virus or any other computer code, file or
              program designed to interrupt, destroy, or limit the functionality
              of any computer resource;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              is patently false and untrue, and is written or published in any
              form, with the intent to mislead or harass a person, entity, or
              agency for financial gain or to cause any injury to any person;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              disrupt or interfere with the security of, or otherwise cause harm
              to, the Platform, systems resources, accounts, passwords, servers,
              or networks connected to or accessible through the Platform or any
              affiliated or linked sites;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              violate the Terms contained herein or elsewhere and/or the Privacy
              Policy; and
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              reverse engineer, modify, copy, distribute, transmit, display,
              perform, reproduce, publish, license, create derivative works
              from, transfer, or sell any information or software obtained from
              the Platform.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="13">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You shall solely be responsible for maintaining the necessary
              computer equipment and internet connections that may be required
              to access, use, and transact on the Platform.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c17 c8"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="14">
          <li className="c2 li-bullet-0">
            <span className="c16">
              Except as expressly indicated herein, the Company hereby grants
              You a non-exclusive, freely revocable (upon notice from the
              Company), non-transferable access to view any Content available on
              the Platform, subject to the following conditions:{" "}
            </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c17 c8"></span>
        </p>
        <ol className="c4 lst-kix_fsqokrgcevtn-0 start" start="1">
          <li className="c6 li-bullet-0">
            <span className="c16">
              You may access the Content solely for personal or informational
              purposes, in accordance with the Terms;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c16">
              You may not modify or alter the Content available on the Platform;
            </span>
            <span className="c8">&nbsp;</span>
            <span className="c16">and</span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c16">
              You may not remove any text, copyright or other proprietary
              notices contained in the Content downloaded from the Platform.
            </span>
            <span className="c1">&nbsp;</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c1 c21"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="15">
          <li className="c2 li-bullet-0">
            <span className="c16">
              Your use of the Platform shall be only for personal purposes. Your
              use of the Platform shall indicate that You have provided consent
              to automatically receive updates such as bug fixes, patches,
              enhanced functions, missing plug-ins and new versions
              (collectively, &lsquo;
            </span>
            <span className="c8">Updates</span>
            <span className="c0">
              &rsquo;), for the purpose of effective delivery of the Services.
              Please note that Your continued use of the Platform following such
              Updates would mean deemed acceptance by You of the same.
            </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-0" start="5">
          <li className="c2 li-bullet-0">
            <span className="c1">Intellectual Property Rights </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c1 c21"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="16">
          <li className="c2 li-bullet-0">
            <span className="c16">
              The Platform and the processes, and their selection and
              arrangement, including but not limited to, all text, reports
              generated, videos, graphics, user interfaces, visual interfaces,
              sounds and music (if any), artwork and computer code (and any
              combinations thereof) and any information or materials uploaded by
              the Users (collectively, the &ldquo;
            </span>
            <span className="c8">Content</span>
            <span className="c0">
              &rdquo;) on the Platform is owned and controlled by the Company
              and the design, structure, selection, coordination, expression,
              look and feel and arrangement of such Content is protected by
              copyright, patent and trademark laws, and various other
              intellectual property rights. You are not entitled to duplicate,
              distribute, create derivative works of, display, or commercially
              exploit the Content, features or facilities, directly or
              indirectly, without Our prior written permission. If You would
              like to request permission in respect of any particular Content,
              You can contact Us in the manner provided for in these Terms.
            </span>
          </li>
        </ol>
        <p className="c5">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="17">
          <li className="c2 li-bullet-0">
            <span className="c16">
              The trademarks, logos and service marks displayed on the Platform
              (&ldquo;
            </span>
            <span className="c8">Marks</span>
            <span className="c0">
              &rdquo;) are the property of the Company or other respective third
              parties, as the case may be. You are not permitted to use the
              Marks without the prior consent of the Company or the third party
              that may own the Marks.
            </span>
          </li>
        </ol>
        <p className="c9">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="18">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You agree and acknowledge that the Company reserves the right to
              use any recourse available to it under applicable laws if it
              believes that You have infringed any of its intellectual property
              rights. &nbsp;
            </span>
          </li>
        </ol>
        <p className="c9">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-0" start="6">
          <li className="c2 li-bullet-0">
            <span className="c1">
              Disclaimers of Warranties and Liabilities
            </span>
          </li>
        </ol>
        <p className="c14 c22">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_aax8fik85dck-1" start="19">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You expressly understand and agree that, to the maximum extent
              permitted by applicable law that:
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_u6orge8dsaaj-0 start" start="1">
          <li className="c6 li-bullet-0">
            <span className="c0">
              The Platform and other Content are provided by the Company on an
              &ldquo;as is&rdquo; basis without warranty of any kind, express,
              implied, statutory, or otherwise, including the implied warranties
              of title, non-infringement, merchantability, or fitness for a
              particular purpose. Without limiting the foregoing, the Company
              makes no warranty that (i) the Platform or Services will meet Your
              requirements, or Your use of the Platform will be uninterrupted,
              timely, secure or error-free; (ii) the results that may be
              obtained from the use of the Platform will be effective, accurate
              or reliable; and (iii) any errors or defects in the Platform will
              be corrected. No advice or information, whether oral or written,
              obtained by You from the Company shall create any warranty not
              expressly stated in the Terms.
            </span>
          </li>
        </ol>
        <p className="c10 c11">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_u6orge8dsaaj-0" start="2">
          <li className="c6 li-bullet-0">
            <span className="c0">
              Company will have no liability related to any user Content arising
              under intellectual property rights, libel, privacy, publicity,
              obscenity, or other laws. Company also disclaims all liability
              with respect to the misuse, loss, modification, or unavailability
              of any user Content.
            </span>
          </li>
        </ol>
        <p className="c10 c11">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_u6orge8dsaaj-0" start="3">
          <li className="c6 li-bullet-0">
            <span className="c0">
              Company will not be liable for any loss that You may incur as a
              consequence of unauthorized use of Your Account or Account
              information in connection with the Platform either with or without
              Your knowledge.
            </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_u6orge8dsaaj-0" start="4">
          <li className="c6 li-bullet-0">
            <span className="c0">
              Company has endeavored to ensure that all the information on the
              Platform is correct, but the Company neither warrants nor makes
              any representations regarding the quality, accuracy or
              completeness of any data, information regarding the Services or
              otherwise. The Company shall not be responsible for the delay or
              inability to use the Platform or related functionalities, the
              provision of or failure to provide functionalities, or for any
              information, software, functionalities and related graphics
              obtained through the Platform, or otherwise arising out of the use
              of the Platform, whether based on contract, tort, negligence,
              strict liability or otherwise. Further, the Company shall not be
              held responsible for non-availability of the Platform during
              periodic maintenance operations or any unplanned suspension of
              access to the Platform that may occur due to technical reasons or
              for any reason beyond the Company&rsquo;s control. You understand
              and agree that any material or data downloaded or otherwise
              obtained through the Platform is done entirely at Your own
              discretion and risk, and that You will be solely responsible for
              any damage to Your computer systems or loss of data that results
              from the download of such material or data.{" "}
            </span>
          </li>
        </ol>
        <p className="c10 c25">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_u6orge8dsaaj-0" start="5">
          <li className="c6 li-bullet-0">
            <span className="c0">
              These Terms are intended for Users within the territory of India
              and govern Your rights as per applicable law within the territory
              of India. However, in the event You fall under a jurisdiction
              outside the purview of Indian law, We will not be liable for any
              claim, action and/or right initiated/exercised by You as per the
              extant laws of that jurisdiction. Therefore, We request You to
              kindly use the Platform accordingly.{" "}
            </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c1 c21"></span>
        </p>
        <ol className="c4 lst-kix_su3cw9ujxk12-0 start" start="8">
          <li className="c2 li-bullet-0">
            <span className="c1">
              Indemnification and Limitation of Liability
            </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_su3cw9ujxk12-1 start" start="1">
          <li className="c2 li-bullet-0">
            <span className="c16">
              You agree to indemnify, defend and hold harmless the Company, and
              its affiliates including but not limited to its (and its
              affiliates&rsquo;) officers, directors, consultants, agents,
              representatives and employees (&ldquo;
            </span>
            <span className="c8">Indemnitees</span>
            <span className="c0">
              &rdquo;) from and against any and all losses, liabilities, claims,
              damages, demands, costs and expenses (including reasonable legal
              fees) asserted against or incurred by the Indemnitees that arise
              out of, result from, or may be payable by virtue of, any breach or
              non-performance of any representation, warranty, covenant or
              agreement made or obligation to be performed by You pursuant to
              these Terms and/or the Privacy Policy. Further, You agree to hold
              the Indemnitees harmless against any claims made by any third
              party due to, or arising out of, or in connection with, Your use
              of the Platform, any misrepresentation with respect to the data or
              information provided by You, Your violation of the Terms and/or
              the Privacy Policy, Your violation of applicable laws, or Your
              violation of any rights of another, including any intellectual
              property rights.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_su3cw9ujxk12-1" start="2">
          <li className="c2 li-bullet-0">
            <span className="c0">
              In no event shall the Company and its officers, partners,
              consultants, agents and employees, be liable to You or any third
              party for any special, incidental, indirect, consequential or
              punitive damages whatsoever, arising out of or in connection with
              Your use of or access to the Platform or Content on the Platform.{" "}
            </span>
          </li>
        </ol>
        <p className="c5">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_su3cw9ujxk12-1" start="3">
          <li className="c2 li-bullet-0">
            <span className="c0">
              The limitations and exclusions in this Section apply to the
              maximum extent permitted by applicable laws.{" "}
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_su3cw9ujxk12-0" start="9">
          <li className="c2 li-bullet-0">
            <span className="c1">Infringement of Intellectual Property</span>
          </li>
        </ol>
        <p className="c9">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_su3cw9ujxk12-1" start="4">
          <li className="c2 li-bullet-0">
            <span className="c0">
              If You believe the Platform violates Your intellectual property,
              You must promptly notify the Company in writing at
              support@emailninja.tech. These notifications should only be
              submitted by the owner of the intellectual property, or an agent
              authorized to act on his/her behalf. However, any false claim by
              You may result in the termination of Your access to the Platform.
              You are required to provide the following details in Your notice:{" "}
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_vpxf4k7k6jyd-0 start" start="1">
          <li className="c6 li-bullet-0">
            <span className="c0">
              the intellectual property that You believe is being infringed;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              the item that You think is infringing and include sufficient
              information about where the material is located on the Platform;
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              a statement that You believe in good faith that the item You have
              identified as infringing is not authorized by the intellectual
              property owner, its agent, or the law to be used in connection
              with the Platform;{" "}
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              Your contact details, such as Your address, telephone number,
              and/or email;{" "}
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">
              a statement that the information You provided in Your notice is
              accurate, and that You are the intellectual property owner, or an
              agent authorized to act on behalf of the intellectual property
              owner whose intellectual property is being infringed; and{" "}
            </span>
          </li>
          <li className="c6 li-bullet-0">
            <span className="c0">Your physical or electronic signature.</span>
          </li>
        </ol>
        <p className="c3">
          <span className="c1 c21"></span>
        </p>
        <ol className="c4 lst-kix_1f2sx3bf4x8-0 start" start="10">
          <li className="c2 li-bullet-0">
            <span className="c1">Violation of the Terms </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_1f2sx3bf4x8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You agree that any violation by You of these Terms will constitute
              an unlawful and unfair business practice, and will cause
              irreparable harm to the Company, for which monetary damages would
              be inadequate, and You consent to the Company obtaining any
              injunctive or equitable relief that they deem necessary or
              appropriate in such circumstances. These remedies are in addition
              to any other remedies that the Company may have at law or in
              equity.{" "}
            </span>
          </li>
        </ol>
        <p className="c14">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_1f2sx3bf4x8-0" start="11">
          <li className="c2 li-bullet-0">
            <span className="c1">Suspension and Termination </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_1f2sx3bf4x8-1" start="2">
          <li className="c2 li-bullet-0">
            <span className="c0">
              These Terms will continue to apply until terminated by either You
              or the Company as set forth below. If You object to the Terms or
              are dissatisfied with the Platform, Your only recourse is to (i)
              close Your Account on the Platform; and/or (ii) stop accessing the
              Platform.
            </span>
          </li>
        </ol>
        <p className="c5">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_1f2sx3bf4x8-1" start="3">
          <li className="c2 li-bullet-0">
            <span className="c0">
              The Company may delist You or block Your future access to the
              Platform or suspend or terminate Your Account if it believes, in
              its sole and absolute discretion that You have infringed,
              breached, violated, abused, or unethically manipulated or
              exploited any term of these Terms or the Privacy Policy or anyway
              otherwise acted unethically.&nbsp;Notwithstanding anything in this
              clause, these Terms will survive indefinitely unless and until the
              Company chooses to terminate them.
            </span>
          </li>
        </ol>
        <p className="c9">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_1f2sx3bf4x8-1" start="4">
          <li className="c2 li-bullet-0">
            <span className="c0">
              If You or the Company terminate Your use of the Platform, the
              Company may delete any Content or other materials relating to You
              and the Company shall have no liability to You or any third party
              for doing so. However, Your transaction details may be preserved
              by the Company for purposes of tax or regulatory compliance.
            </span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-0 start" start="11">
          <li className="c2 li-bullet-0">
            <span className="c1">Governing Law and Jurisdiction</span>
          </li>
        </ol>
        <p className="c9">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1 start" start="1">
          <li className="c2 li-bullet-0">
            <span className="c0">
              These Terms shall be governed by and constructed in accordance
              with the laws of India without reference to conflict of laws
              principles and disputes arising in relation hereto shall be
              subject to the exclusive jurisdiction of courts at Bangalore,
              Karnataka, India.{" "}
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-0" start="12">
          <li className="c2 li-bullet-0">
            <span className="c1">Report Abuse</span>
          </li>
        </ol>
        <p className="c3 c18">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1" start="2">
          <li className="c2 li-bullet-0">
            <span className="c0">
              In the event You come across any abuse or violation of these Terms
              or if You become aware of any objectionable content on the
              Platform, please report the same to the following e-mail id:
              support@emailninja.tech
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c1 c21"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-0" start="13">
          <li className="c2 li-bullet-0">
            <span className="c1">Communications</span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0">
            <br />
          </span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1" start="3">
          <li className="c2 li-bullet-0">
            <span className="c0">
              You hereby expressly agree to receive communications by way of
              emails from the Company and other third parties duly authorized by
              the Company. You certify, warrant and represent that the email
              addresses and any other information that You have provided to Us
              are Your own and are true, accurate, current and complete. You can
              unsubscribe or opt-out from receiving communications through
              e-mail anytime by writing to Us at support@emailninja.tech. In
              which case, the Company will only send You communications solely
              required for the purposes of the Services provided through the
              Platform.
            </span>
          </li>
        </ol>
        <p className="c10 c15">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-0" start="14">
          <li className="c2 li-bullet-0">
            <span className="c1">Grievance Redressal Mechanism</span>
          </li>
        </ol>
        <p className="c9">
          <span className="c1 c21"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1" start="4">
          <li className="c2 li-bullet-0">
            <span className="c8">Grievance Handling</span>
            <span className="c0">
              : If You have any grievances, or complaints or concerns with
              respect to the Platform, the Content, or the Services, You can
              contact the designated Grievance Officer of the Company, namely,
              Sugath Surendran, at support@emailninja.tech
            </span>
          </li>
        </ol>
        <p className="c10 c15">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1" start="5">
          <li className="c2 li-bullet-0">
            <span className="c0">
              By lodging a complaint or grievance, You agree to provide complete
              support to the Grievance Officer and such reasonable information
              as may be sought by them from You.
            </span>
          </li>
        </ol>
        <p className="c14 c13">
          <span className="c0">&nbsp;</span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-0" start="15">
          <li className="c2 li-bullet-0">
            <span className="c1">General Provisions</span>
          </li>
        </ol>
        <p className="c10">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1" start="6">
          <li className="c2 li-bullet-0">
            <span className="c0">
              All Services on the Platform are provided to You on a
              principal-to-principal basis between You and the Company. Nothing
              herein is intended to nor be construed to constitute the
              relationship of a principal and agent, employer and employee,
              partners, joint venture, co-owners or otherwise as participants in
              a joint undertaking or representative of the other for any purpose
              whatsoever.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1" start="7">
          <li className="c2 li-bullet-0">
            <span className="c8">Notice</span>
            <span className="c0">
              : All notices from the Company will be served by email to Your
              registered email address or by general notification on the
              Platform.{" "}
            </span>
          </li>
        </ol>
        <p className="c10 c11">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1" start="8">
          <li className="c2 li-bullet-0">
            <span className="c8">Assignment</span>
            <span className="c0">
              : You cannot assign or otherwise transfer these Terms, or any
              rights granted hereunder to any third party. The Company&rsquo;s
              rights under the Terms are freely transferable by the Company to
              any third party without the requirement of seeking Your consent.
            </span>
          </li>
        </ol>
        <p className="c10 c11">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1" start="9">
          <li className="c2 li-bullet-0">
            <span className="c8">Severability</span>
            <span className="c0">
              : If, for any reason, a court of competent jurisdiction finds any
              provision of the Terms, or any portion thereof, to be
              unenforceable, that provision shall be enforced to the maximum
              extent permissible so as to give effect to the intent of the
              parties as reflected by that provision, and the remainder of the
              Terms shall continue in full force and effect.
            </span>
          </li>
        </ol>
        <p className="c10 c11">
          <span className="c0"></span>
        </p>
        <ol className="c4 lst-kix_g0wb47k0ykcf-1" start="10">
          <li className="c2 li-bullet-0">
            <span className="c8">Waiver</span>
            <span className="c0">
              : Any failure by Company to enforce or exercise any provision of
              the Terms, or any related right, shall not constitute a waiver by
              Company of that provision or right.
            </span>
          </li>
        </ol>
        <p className="c3">
          <span className="c0"></span>
        </p>
        <p className="c10">
          <span className="c8 c26 c27"></span>
        </p>
        <p className="c14">
          <span className="c8 c26">Copyright &copy; All rights reserved.</span>
        </p>
      </div>
    </Box>
  );
};

export default TermsOfUse;
