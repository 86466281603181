import { COVERAGE, EMAIL_FILTER_TYPE } from "config/constants";

export const dashboardTypes = {
  INIT: "DASHBOARD_INIT",
  SUCCESS: "DASHBOARD_SUCCESS",
  FAILURE: "DASHBOARD_FAILURE",
  DASHBOARD_COVERAGE_RESET: "DASHBOARD_COVERAGE_RESET",
  UPDATED_FILTER_VALUE: "UPDATED_FILTER_VALUE",
  RESET_MORE_DATA_FLAG: "RESET_MORE_DATA_FLAG",
  UPDATE_CATEGORY_TAG_COLOR: "UPDATE_CATEGORY_TAG_COLOR",
};

export type HourDetailsType = {
  hour: number;
  sent: number;
  received: number;
};
export type HourlyViewType = {
  hoursDetails: HourDetailsType[];
};

export type WeekDetailsType = {
  day: string;
  sent: number;
  received: number;
};
export type WeeklyViewType = {
  weekDetails: WeekDetailsType[];
};

export type SummaryDetailsListType = {
  title: string;
  count: number;
  description: string;
};
export type SummaryViewType = {
  weekDetails: SummaryDetailsListType[];
};

export type InteractionsType = {
  emailAddress: string;
  sent: number;
  received: number;
};
export type InteractionViewType = {
  weekDetails: InteractionsType[];
};

export type ProfileType = {
  email: string;
  updatedAt?: string;
};

const emailInsightsDefault = {
  categoryView: null,
  hourlyView: null,
  interactionView: null,
  summaryView: null,
  weeklyView: null,
  profile: null,
};
export type EmailInsightsType = {
  categoryView: any;
  hourlyView: HourlyViewType | null;
  interactionView: InteractionsType | null;
  summaryView: SummaryViewType | null;
  weeklyView: WeeklyViewType | null;
  profile?: ProfileType | null;
};

export type DashboardState = {
  emailInsights: EmailInsightsType;
  coverage?: string;
  loading?: boolean;
  selectedFilter?: string;
  isMoreData?: boolean;
  categoryTagColor: any;
};

export const dashboardInitialState: DashboardState = {
  loading: false,
  coverage: "",
  emailInsights: emailInsightsDefault,
  selectedFilter: EMAIL_FILTER_TYPE.MONTHLY,
  isMoreData: false,
  categoryTagColor: null,
};

const dashboardReducer = (state: DashboardState, action: any) => {
  switch (action.type) {
    case dashboardTypes.INIT: {
      return {
        ...state,
        emailInsights: emailInsightsDefault,
        loading: true,
        coverage: "",
      };
    }
    case dashboardTypes.DASHBOARD_COVERAGE_RESET: {
      return {
        ...state,
        coverage: "",
      };
    }

    case dashboardTypes.RESET_MORE_DATA_FLAG: {
      return {
        ...state,
        isMoreData: "",
      };
    }

    case dashboardTypes.SUCCESS: {
      const {
        emailInsights = emailInsightsDefault,
        coverage = "",
        profile = null,
      } = action.payload;
      let isMoreData = true;

      if (coverage === COVERAGE.FULL) {
        isMoreData = false;
      }

      return {
        ...state,
        loading: false,
        emailInsights,
        coverage,
        profile,
        isMoreData,
        // profile: { ...profile, updatedAt: "Aug 24 2022" },
      };
    }
    case dashboardTypes.UPDATED_FILTER_VALUE: {
      return {
        ...state,
        selectedFilter: action.payload,
      };
    }

    case dashboardTypes.UPDATE_CATEGORY_TAG_COLOR: {
      return {
        ...state,
        categoryTagColor: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default dashboardReducer;
