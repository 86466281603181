import EmailAnalyticsContext from "contexts/EmailAnalytics";
import { useContext } from "react";

const useEmailAnalytics = () => {
  const data = useContext(EmailAnalyticsContext) as any;
  return data[0];
};

export const useDispatch = () => {
  const data = useContext(EmailAnalyticsContext) as any;
  return data[1];
};

export default useEmailAnalytics;
