import { Box } from "@mui/system";
import "./style.scss";

export const Faqs = () => {
  return (
    <Box
      width="100%"
      justifyContent="center"
      display="flex"
      className="faqs-content"
      >
      <div className="c12 doc-content">
          <p className="c5"><span className="c10 c1">Frequently Asked Questions</span></p>
          <p className="c2 c5"><span className="c1 c10"></span></p>
          <p className="c0 c2"><span className="c4 c7"></span></p>
          <ol className="c6 lst-kix_lne3t1ulzjd8-0 start" start={1}>
            <li className="c0 c3 li-bullet-0"><span className="c4 c7">What is EmailNinja?</span></li>
          </ol>
          <p className="c5"><span className="c4 c1">Email Ninja is a web app that will help you to:</span></p>
          <ul className="c6 lst-kix_ptewniqtbsei-0 start">
            <li className="c8 c11 c3 li-bullet-0"><span className="c1">See your Gmail interactions</span><span
                className="c4 c1">&nbsp;with different people and businesses so that you can determine your network
                strength</span>
            </li>
            <li className="c8 c11 c3 li-bullet-0"><span className="c4 c1">Get insights about your email usage patterns and see how
                many businesses send you mails across a time period</span>
            </li>
            <li className="c8 c11 c3 li-bullet-0"><span className="c4 c1">To show the top email senders and receivers so that you
                can make informed choices about your subscriptions to make the most out of your email</span>
            </li>
            <li className="c8 c3 c11 li-bullet-0"><span className="c1">See </span><span className="c1">how many mails in your inbox are
                sent by different businesses</span><span className="c1">&nbsp;and </span><span className="c1">how much time it
                will take to read all those emails</span>
            </li>
          </ul>
          <p className="c8 c2"><span className="c4 c7"></span></p>
          <ol className="c6 lst-kix_lne3t1ulzjd8-0" start={2}>
            <li className="c0 c3 li-bullet-0"><span className="c4 c7">How does EmailNinja show my email insights?</span></li>
          </ol>
          <p className="c0"><span className="c4 c1">EmailNinja tracks the email activity and calculates your usage and interactions to
            present meaningful insights about your emails. </span>
          </p>
          <p className="c0"><span className="c4 c1">We calculate and present the following metrics to you:</span></p>
          <ul className="c6 lst-kix_6gn35hk26s3v-0 start">
            <li className="c8 c11 c3 li-bullet-0"><span className="c4 c1">Quantum of emails sent and received</span></li>
            <li className="c8 c11 c3 li-bullet-0"><span className="c4 c1">Email usage patterns</span></li>
            <li className="c8 c11 c3 li-bullet-0"><span className="c4 c1">Top senders and receivers</span></li>
            <li className="c8 c11 c3 li-bullet-0"><span className="c4 c1">Interactions with businesses and volume of mails sent by
                them</span>
            </li>
          </ul>
          <p className="c0 c2"><span className="c4 c7"></span></p>
          <ol className="c6 lst-kix_lne3t1ulzjd8-0" start={3}>
            <li className="c0 c3 li-bullet-0"><span className="c7 c14">Are there any charges for using Email Ninja</span><span
                className="c7">?</span></li>
          </ol>
          <p className="c8"><span className="c1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No, it is a free-of-cost tool
            available to all users. No subscription or credit card information required.</span>
          </p>
          <p className="c2 c8"><span className="c4 c1"></span></p>
          <ol className="c6 lst-kix_lne3t1ulzjd8-0" start={4}>
            <li className="c0 c3 li-bullet-0"><span className="c4 c7">Do you have access to or store my emails?</span></li>
          </ol>
          <p className="c0"><span className="c4 c1">We only have read access to your email data. We store the data in an encrypted
            manner. </span>
          </p>
          <p className="c0 c2"><span className="c4 c1"></span></p>
          <p className="c0"><span className="c1">Email Ninja&rsquo;s usage of information received from Gmail APIs will comply with
            and adhere to <a className="c9-custom" target="_blank" href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>,
            including the Limited Use requirements.</span>
          </p>
          <p className="c8 c2"><span className="c4 c1"></span></p>
          <ol className="c6 lst-kix_lne3t1ulzjd8-0" start={5}>
            <li className="c0 c3 li-bullet-0"><span className="c4 c7">Is my Gmail data secure? </span></li>
          </ol>
          <p className="c0"><span className="c4 c1">We use the Gmail API to get read-only access to your email data, ensuring 100%
            privacy &amp; security. The retrieval process is carried out on a fully-encrypted connection. </span>
          </p>
          <p className="c0"><span className="c1">Our servers and database run on A</span><span className="c4 c1">mazon Web Services (AWS)
            which provides data encryption in motion and at-rest in the database. All communication between Gmail and
            Email Ninja servers is protected and encrypted.</span>
          </p>
          <p className="c0"><span className="c4 c1">We do not have access to alter or change your email data.</span></p>
          <p className="c0 c2"><span className="c4 c7"></span></p>
          <ol className="c6 lst-kix_lne3t1ulzjd8-0" start={6}>
            <li className="c0 c3 li-bullet-0"><span className="c4 c7">Does it work with Yahoo and Outlook?</span></li>
          </ol>
          <p className="c0"><span className="c1 c4">Currently, we only support analytics for Gmail inbox.</span></p>
          <p className="c0 c2"><span className="c4 c7"></span></p>
          <ol className="c6 lst-kix_lne3t1ulzjd8-0" start={7}>
            <li className="c0 c3 li-bullet-0"><span className="c4 c7">How can I revoke access to my Gmail data?</span></li>
          </ol>
          <p className="c0"><span className="c4 c1">You can manage access to your Google account and review the type as well as the
            Google services we have access to, by going to the Security section of your Google Account. </span>
          </p>
          <p className="c0"><span className="c1">To learn more about how Google helps users share their data safely, please go through
            the article here: </span><span className="c7 c13"><a className="c9"
                href="https://www.google.com/url?q=https://support.google.com/accounts/answer/10130420?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1663078927583038&amp;usg=AOvVaw2lHsQ0MJly3PR_q2nSgRWA">https://support.google.com/accounts/answer/10130420?hl=en</a></span>
          </p>
          <p className="c0 c2"><span className="c4 c7"></span></p>
          <ol className="c6 lst-kix_lne3t1ulzjd8-0" start={8}>
            <li className="c0 c3 li-bullet-0"><span className="c4 c7">How can I place a request for deletion of my personal data
                from EmailNinja?</span>
            </li>
          </ol>
          <p className="c0"><span className="c1">You can request the deletion or removal of your personal information by </span><span
            className="c1">writing</span><span className="c4 c1">&nbsp;to us at support@emailninja.tech.</span></p>
          <p className="c0 c2"><span className="c4 c1"></span></p>
          <p className="c0 c2"><span className="c4 c1"></span></p>
          <p className="c0 c2"><span className="c4 c1"></span></p>
          <p className="c0"><span className="c4 c1">If you have any other questions or feedback, you can contact us by sending us an
            email at support@emailninja.tech.</span>
          </p>
          <p className="c8"><span className="c1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
          <p className="c8 c2"><span className="c4 c1"></span></p>
      </div>
    </Box>
  );
};

export default Faqs;
