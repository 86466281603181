import { Box } from "@mui/material";
import { FC } from "react";

import useEmailAnalytics from "hooks/useEmailAnalytics";

import useStyles from "./styles";
import { COVERAGE } from "config/constants";
import { getAroundTime } from "../helper";

type AnnouncementProps = {};

const Announcement: FC<AnnouncementProps> = () => {
  const css = useStyles();

  const {
    dashboardReducer: {
      profile,
      coverage,
      emailInsights: { categoryView },
    },
  } = useEmailAnalytics();

  if (profile?.email && coverage && coverage !== COVERAGE.PROCESSING) {
    return (
      <Box className={css.annoucementContainer}>
        <Box className={"annoucement"}>
          <Box ml={2} className={"msg"}>
            It will take you around{" "}
            <b>{getAroundTime(categoryView?.totalEmails)}</b> to read all the
            emails received from businesses in your inbox.
          </Box>
        </Box>
        <Box className="image">
          <img
            src="/static/illustration/did-you-know.svg"
            alt="did you know"
            height={64}
          />
        </Box>
      </Box>
    );
  }
  return null;
};

export default Announcement;
