export type UserState = {
  authUrl: string;
  isAlreadyConnected?: boolean;
  loading?: boolean;
};

export const userInitialState: UserState = {
  loading: false,
  isAlreadyConnected: false,
  authUrl: "",
};

const userReducer = (state: UserState, action: any) => {
  switch (action.type) {
    case "INIT": {
      return {
        ...state,
        authUrl: "",
        loading: true,
      };
    }
    case "GMAIL_SUCCESS": {
      return { ...state, loading: false, authUrl: action.payload.authUrl };
    }
    case "REQUEST_COMPLETE": {
      return {
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
