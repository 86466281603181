import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    annoucementContainer: {
      position: "relative",
      width: "50%",
      [theme.breakpoints.down(600)]: {
        width: "90%",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      },
      display: "flex",
      margin: "auto",
      marginTop: 20,
      "& .annoucement": {
        width: "100%",
        display: "flex",
        backgroundColor: "white",
        alignItems: "center",
        zIndex: 11,
        borderRadius: 14,
        padding: 18,
        "& .msg": {
          fontWeight: 500,
          color:
            theme.palette.mode === "dark"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
        },
        marginBottom: 30,
        marginTop: 30,
        paddingLeft: 44,
        fontFamily: "neue_haas_grotesk_disp55roman !important",
        '& b': {
          fontFamily: "neue_haas_grotesk_display65Md"
        }
      },
      "& .image": {
        position: "absolute",
        zIndex: 11,
        top: -30,
        left: -85,
        [theme.breakpoints.down(600)]: {
          top: 0,
          left: -15,
        },
        "& > img": {
          height: 108,
          [theme.breakpoints.down(600)]: {
            height: 68,
          },
        },
      },
    },
  });
});

export default useStyles;
